import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import posed from 'react-pose';
import Head from 'components/head';
import Header from 'components/header';
import Footer from 'components/footer';
import Sidebar from 'components/sidebar';
import GlobalStyle from 'global.css.js';

const Layout = ({ data, children }) => (
  <>
    <GlobalStyle />
    <AnimatedContainer>
      <Head />
      <Sidebar />
      <Header title={data.site.siteMetadata.siteTitle} />
      {children}
      <Footer />
    </AnimatedContainer>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    z: 0,
    opacity: 1,
    transition: { duration: 1500, ease: 'linear' },
    delay: 0,
    delayChildren: 0,
  },
  exit: {
    z: -500,
    opacity: 0,
    transition: { duration: 1500, ease: 'linear' },
  },
});

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
