import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { Container } from './sidebar.css';

const Sidebar = () => (
  <Container>
    <Menu width={'100%'} right>
      <a className="menu-item" href="/">
        Home
      </a>

      <a className="menu-item" href="/about">
        About Us
      </a>

      <a className="menu-item" href="/artists">
        Artists
      </a>

      <a className="menu-item" href="/news">
        News
      </a>

      <a className="menu-item" href="/contact">
        Contact
      </a>
    </Menu>
  </Container>
);

export default Sidebar;
