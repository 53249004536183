import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4rem 20px;
  max-width: 100%;
  margin: 0px auto;
  z-index: 5;
  position: relative;

  a {
    position: relative;
    transition: color 0.2s ease;

    width: 90px;
    height: 50px;
    text-indent: -9999px;
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
    &:hover {
      color: inherit;
    }
  }
`;
