import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
  ${MEDIA.TABLET`
    display: none;
  `};
  ul {
    display: flex;
    list-style: none;
    padding: 0;

    li {
      font-size: 1.5rem;

      a {
        color: white;
      }

      & + li {
        margin-left: 6rem;
      }
    }
  }
`;
