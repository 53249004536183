import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';
import Logo from '../../images/logo.svg';

const Header = ({ title }) => (
  <Container>
    <Link to="/">
      <Title as="h1">
        {title}
        <Logo />
      </Title>
    </Link>

    <Nav />
  </Container>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
