import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.footer`
  padding: 4rem 20px;
  position: relative;
  text-align: center;
  transition: color 1s ease;
  color: #7c7c7c;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  max-width: 100%;
  margin: 280px auto 0;
  font-size: 1.5rem;

  ${MEDIA.TABLET`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  `};
  a {
    text-decoration: none;
    transition: color 1s ease;
  }
  .copyright {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    display: grid;
    justify-items: start;
    ${MEDIA.TABLET`
    grid-area: 2 / 1 / 3 / 2;
  `};
  }
  .socials {
    ${MEDIA.TABLET`
    grid-area: 1 / 1 / 2 / 3;
  `};

    ul {
      li {
        display: inline-block;

        &:after {
          content: '';
          height: 2px;
          width: 50px;
          display: inline-block;
          background-color: #7c7c7c;
          margin: 0 10px;
          ${MEDIA.TABLET`
            width: 25px;
          `};
        }
        &:last-child:after {
          width: 0;
        }
      }
    }
  }
  .back-to-top {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    display: grid;
    justify-items: end;
    ${MEDIA.TABLET`
    grid-area: 2 / 2 / 3 / 3;
  `};
    a {
      color: #7c7c7c;
    }
  }
`;
