import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: none;
  ${MEDIA.TABLET`
    display: block;
  `};
  button {
    outline: 0;
  }
  /* Individual item */
  .bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 15px;
    color: #ffffff;
    transition: color 0.2s;
    outline: 0;
    text-align: center;
  }

  /* Change color on hover */
  .bm-item:hover {
    color: white;
  }

  /* The rest copied directly from react-burger-menu docs */

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 35px;
    height: 25px;
    right: 5%;
    top: 50px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #fff;
    height: 1px !important;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    width: 35px !important;
    height: 35px !important;
    right: 5% !important;
    top: 20px !important;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #fff;
    width: 2px !important;
    height: 35px !important;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #000;
    padding: 25vh 1.5em 0 1.5em;
    font-size: 1.15em;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #fff;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .bm-menu-wrap {
    transition: all 0.8s ease 0s;
  }
`;
