import React from 'react';
import { Link } from 'gatsby';
import { Container } from './footer.css';

// <IO> uses a render prop to pass down `isVisible` and `hasBeenVisible`.
// In this example, we only care about `isVisible` and reset the styles
// every time we scroll back up. Use `hasBeenVisible` to keep the styles
// after scrolling back up and down again.

const Footer = () => (
  <Container>
    <div className="copyright">© {new Date().getFullYear()}</div>
    <div className="socials">
      <ul>
        <li>
          <a
            href="https://facebook.com/ASongsPublishing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/ASongsUK"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/asongspublishing/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
        </li>
      </ul>
    </div>
    <div className="back-to-top">
      <Link to="/">Back To Top</Link>
    </div>
  </Container>
);

export default Footer;
